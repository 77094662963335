var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order"},[_c('div',{staticClass:"orders"},[_c('div',{staticClass:"basicsName"},[_c('div',{staticClass:"basicsNames"},[_c('div',{staticClass:"basicsNamesTop",class:[
          _vm.couponType == 1 && _vm.orderStates == 0 ? 'basicsNamesTops' : ''
        ],on:{"click":function($event){return _vm.navChange(0, 1)}}},[_vm._v(" 无门槛券 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.couponType == 1 && _vm.orderStates == 0),expression:"couponType == 1 && orderStates == 0"}],staticClass:"basicsNamesCen"})]),_c('div',{staticClass:"basicsNames"},[_c('div',{staticClass:"basicsNamesTop",class:[
          _vm.couponType == 2 && _vm.orderStates == 0 ? 'basicsNamesTops' : ''
        ],on:{"click":function($event){return _vm.navChange(0, 2)}}},[_vm._v(" 满减券 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.couponType == 2 && _vm.orderStates == 0),expression:"couponType == 2 && orderStates == 0"}],staticClass:"basicsNamesCen"})]),_c('div',{staticClass:"basicsNames"},[_c('div',{staticClass:"basicsNamesTop",class:[
          _vm.couponType == 3 && _vm.orderStates == 0 ? 'basicsNamesTops' : ''
        ],on:{"click":function($event){return _vm.navChange(0, 3)}}},[_vm._v(" 折扣券 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.couponType == 3 && _vm.orderStates == 0),expression:"couponType == 3 && orderStates == 0"}],staticClass:"basicsNamesCen"})]),_c('div',{staticClass:"basicsNames"},[_c('div',{staticClass:"basicsNamesTop",class:[
          _vm.couponType == null && _vm.orderStates == 0 ? 'basicsNamesTops' : ''
        ],on:{"click":function($event){return _vm.navChange(0, null)}}},[_vm._v(" 领券中心 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.couponType == null && _vm.orderStates == 0),expression:"couponType == null && orderStates == 0"}],staticClass:"basicsNamesCen"})])]),_c('div',{staticClass:"tabCar-box"},[(_vm.orderStates == 0 &&
        _vm.couponType == null &&
        _vm.yhqlist &&
        _vm.yhqlist.length > 0
        )?_c('div',{staticClass:"tabCar"},_vm._l((_vm.yhqlist),function(itemx,index){return _c('div',{key:index,staticClass:"Carlist ss"},[_c('div',[_c('div',{staticClass:"CarListContent"},[_c('div',{staticClass:"money"},[(itemx.couponType == 1 || itemx.couponType == 2)?_c('div',{staticClass:"moneys"},[_vm._v(" "+_vm._s(itemx.couponValue)),_c('span',{staticClass:"moneyLog"},[_vm._v("元")])]):_vm._e(),(itemx.couponType == 3)?_c('div',{staticClass:"moneys"},[_vm._v(" "+_vm._s(itemx.fullDiscountRate / 10)+" "),_c('span',{staticClass:"fz-14"},[_vm._v("折")])]):_vm._e(),_c('div',{staticClass:"qlis"},[(itemx.couponType == 1)?_c('div',{staticClass:"text mb-2"},[_vm._v(" 无门槛券 ")]):_vm._e(),(itemx.couponType == 2)?_c('div',{staticClass:"text mb-2"},[_vm._v(" 满减券 ")]):_vm._e(),(itemx.couponType == 3)?_c('div',{staticClass:"text mb-2"},[_vm._v(" 折扣券 ")]):_vm._e()])]),_c('div',{staticClass:"content conflex"},[_c('div',{staticClass:"text-box"},[_c('div',{staticClass:"title elp"},[_c('div',{staticClass:"til_m"},[_vm._v(_vm._s(itemx.couponName))]),_c('div',{staticClass:"time elp"},[_vm._v(" 有效期至"+_vm._s(itemx.validEndTime)+" ")]),_c('div',{staticClass:"syrule"},[_vm._v(" 使用规则： "),_c('div',{staticClass:"rulesOfUse"},[(itemx.couponType == 1 &&
                          itemx.applicableGoodsName &&
                          itemx.fullReductionAmount
                          )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用，只能用于购买${itemx.applicableGoodsName}`)+" ")]):_vm._e(),(itemx.couponType == 1 && itemx.applicableGoodsName
                            )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`只能用于购买${itemx.applicableGoodsName}`)+" ")]):(itemx.couponType == 2 &&
                          itemx.fullReductionAmount &&
                          !itemx.applicableGoodsName
                          )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用`)+" ")]):(itemx.couponType == 2 &&
                          itemx.fullReductionAmount &&
                          itemx.applicableGoodsName
                          )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用，只能用于购买${itemx.applicableGoodsName}`)+" ")]):(itemx.couponType == 3 &&
                            itemx.fullReductionAmount &&
                            !itemx.applicableGoodsName
                            )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用`)+" ")]):(itemx.couponType == 3 &&
                          itemx.fullDiscountRate &&
                          itemx.applicableGoodsName
                          )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用，只能用于购买${itemx.applicableGoodsName}`)+" ")]):_c('div',[_vm._v(" "+_vm._s(`适用于全部商品`)+" ")])])])]),_c('div',{staticClass:"time"},[_c('div',{staticClass:"fifday"}),(itemx.couponType == 2)?_c('div',{staticClass:"mb-2"}):_vm._e(),(itemx.forPeople == 4 && itemx.limitDaysForOlder)?_c('div',{staticClass:"mb-2"},[_c('div',[_vm._v(" "+_vm._s(`注册满${itemx.limitDaysForOlder}天后可领取`)+" ")])]):_vm._e(),(itemx.forPeople == 5 && !itemx.limitLowAmount)?_c('div',{staticClass:"mb-2"},[_c('div',[_vm._v("首次下单完成后可领取")])]):_vm._e(),(itemx.forPeople == 5 && itemx.limitLowAmount)?_c('div',{staticClass:"mb-2"},[_c('div',[_vm._v(" "+_vm._s(`首次下单满${itemx.limitLowAmount}元可领取`)+" ")])]):_vm._e(),(itemx.forPeople == 6 && itemx.limitLowAmount)?_c('div',{staticClass:"mb-2"},[_c('div',[_vm._v(" "+_vm._s(`累计消费满${itemx.limitLowAmount}元可领取`)+" ")])]):_vm._e(),(itemx.forPeople == 6 && !itemx.limitLowAmount)?_c('div',{staticClass:"mb-2"},[_c('div',[_vm._v(" "+_vm._s(`累计消费满${itemx.limitLowAmount}元可领取`)+" ")])]):_vm._e(),(itemx.forPeople == 3)?_c('div',{staticClass:"mb-2"},[_c('div',[_vm._v(_vm._s(`新用户可领取`))])]):_vm._e()])]),_c('div',[(itemx.status != 2)?_c('div',{staticClass:"getQ",on:{"click":function($event){return _vm.getquan(itemx)}}},[_vm._v(" 领 ")]):_vm._e()])])]),_c('div',{staticClass:"rulesOfUseBox"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(itemx.rules),expression:"itemx.rules"}],staticClass:"rulesOfUse"},[(itemx.couponType == 1 &&
                  itemx.applicableGoodsName &&
                  itemx.fullReductionAmount
                  )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用，只能用于购买${itemx.applicableGoodsName}`)+" ")]):_vm._e(),(itemx.couponType == 1 && itemx.applicableGoodsName)?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`只能用于购买${itemx.applicableGoodsName}`)+" ")]):(itemx.couponType == 2 &&
                  itemx.fullReductionAmount &&
                  !itemx.applicableGoodsName
                  )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用`)+" ")]):(itemx.couponType == 2 &&
                  itemx.fullReductionAmount &&
                  itemx.applicableGoodsName
                  )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用，只能用于购买${itemx.applicableGoodsName}`)+" ")]):(itemx.couponType == 3 &&
                    itemx.fullReductionAmount &&
                    !itemx.applicableGoodsName
                    )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用`)+" ")]):(itemx.couponType == 3 &&
                  itemx.fullDiscountRate &&
                  itemx.applicableGoodsName
                  )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${itemx.fullReductionAmount}元可用，只能用于购买${itemx.applicableGoodsName}`)+" ")]):_c('div',[_vm._v(" "+_vm._s(`适用于全部商品`)+" ")])])])])])}),0):(_vm.list && _vm.list.length > 0)?_c('div',{staticClass:"tabCar"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"Carlist",class:_vm.orderStates == 2 ? 'Expired' : _vm.orderStates == 1 ? 'usered' : ''},[_c('div',[_c('div',{class:_vm.orderStates == 0
              ? 'CarListContent'
              : _vm.orderStates == 1
                ? 'CarListContents'
                : 'CarListContentss'},[_c('div',{staticClass:"bqs"}),_c('div',{staticClass:"money"},[(item.couponType == 1 || item.couponType == 2)?_c('div',{staticClass:"moneys"},[_vm._v(" "+_vm._s(item.couponValue)),_c('span',{staticClass:"moneyLog"},[_vm._v("元")])]):_vm._e(),(item.couponType == 3)?_c('div',{staticClass:"moneys"},[_vm._v(" "+_vm._s(item.fullDiscountRate / 10)+" "),_c('span',{staticClass:"fz-14"},[_vm._v("折")])]):_vm._e(),_c('div',{staticClass:"qlis"},[(item.couponType == 3)?_c('div',{staticClass:"text"},[_vm._v("折扣券")]):_vm._e(),(item.couponType == 2)?_c('div',{staticClass:"text"},[_vm._v(" 满"+_vm._s(item.fullReductionAmount)+"元可用 ")]):_vm._e(),(item.couponType == 1)?_c('div',{staticClass:"text mb-2"},[_vm._v(" 无门槛券 ")]):_vm._e()])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title elp"},[_c('div',{staticClass:"til_m"},[_vm._v(_vm._s(item.couponName))])]),_vm._m(0,true),_c('div',{staticClass:"time elp"},[_vm._v("有效期至"+_vm._s(item.validEndTime))]),(_vm.orderStates != 1 && _vm.orderStates != 2)?_c('div',{staticClass:"syrule"},[_vm._v(" 使用规则： "),_c('div',{staticClass:"rulesOfUse"},[(item.couponType == 1 &&
                      item.applicableGoodsName &&
                      item.fullReductionAmount
                      )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用，只能用于购买${item.applicableGoodsName}`)+" ")]):_vm._e(),(item.couponType == 1 && item.applicableGoodsName)?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`只能用于购买${item.applicableGoodsName}`)+" ")]):(item.couponType == 2 &&
                      item.fullReductionAmount &&
                      !item.applicableGoodsName
                      )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用`)+" ")]):(item.couponType == 2 &&
                      item.fullReductionAmount &&
                      item.applicableGoodsName
                      )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用，只能用于购买${item.applicableGoodsName}`)+" ")]):(item.couponType == 3 &&
                        item.fullReductionAmount &&
                        !item.applicableGoodsName
                        )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用`)+" ")]):(item.couponType == 3 &&
                      item.fullDiscountRate &&
                      item.applicableGoodsName
                      )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用，只能用于购买${item.applicableGoodsName}`)+" ")]):_c('div',[_vm._v(" "+_vm._s(`适用于全部商品`)+" ")])])]):_vm._e(),_c('div',{staticClass:"forPeople"},[_vm._v(" "+_vm._s(item.forPeople == 3 || item.forPeople == 4 ? _vm.forPeople[item.forPeople] + '专享' : '')+" ")])])]),_c('div',{staticClass:"rulesOfUseBox"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.rules),expression:"item.rules"}],staticClass:"rulesOfUse"},[(item.couponType == 1 &&
                  item.applicableGoodsName &&
                  item.fullReductionAmount
                  )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用，只能用于购买${item.applicableGoodsName}`)+" ")]):_vm._e(),(item.couponType == 1 && item.applicableGoodsName)?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`只能用于购买${item.applicableGoodsName}`)+" ")]):(item.couponType == 2 &&
                  item.fullReductionAmount &&
                  !item.applicableGoodsName
                  )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用`)+" ")]):(item.couponType == 2 &&
                  item.fullReductionAmount &&
                  item.applicableGoodsName
                  )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用，只能用于购买${item.applicableGoodsName}`)+" ")]):(item.couponType == 3 &&
                    item.fullReductionAmount &&
                    !item.applicableGoodsName
                    )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用`)+" ")]):(item.couponType == 3 &&
                  item.fullDiscountRate &&
                  item.applicableGoodsName
                  )?_c('div',{staticClass:"elp"},[_vm._v(" "+_vm._s(`满${item.fullReductionAmount}元可用，只能用于购买${item.applicableGoodsName}`)+" ")]):_c('div',[_vm._v(" "+_vm._s(`适用于全部商品`)+" ")])])])])])}),0):_vm._e()]),(_vm.couponType == null && _vm.orderStates == 0 && _vm.yhqlist.length == 0)?_c('div',{staticClass:"nothing"},[_c('img',{staticClass:"nothing-img",attrs:{"src":require("@/assets/img/study/noLearn.png")}}),_c('div',{staticClass:"nothing-text"},[_vm._v("暂无优惠券哦~")])]):_vm._e(),((_vm.couponType == 1 ||
      _vm.couponType == 2 ||
      _vm.couponType == 3 ||
      _vm.couponType == null) &&
      (_vm.orderStates == 0 || _vm.orderStates == 1 || _vm.orderStates == 2) &&
      _vm.list.length == 0 &&
      (_vm.couponType !== null || _vm.orderStates !== 0)
      )?_c('div',{staticClass:"nothing"},[_c('img',{staticClass:"nothing-img",attrs:{"src":require("@/assets/img/study/noLearn.png")}}),_c('div',{staticClass:"nothing-text"},[_vm._v("暂无优惠券哦~")])]):_vm._e(),_c('el-pagination',{staticClass:"pagination colleges-pagination",attrs:{"total":_vm.total,"current-page":_vm.pageNum,"page-sizes":[6, 12, 18, 24],"page-size":_vm.pageSize,"background":"","layout":"total, sizes, prev, pager, next"},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time"},[_c('div',{staticClass:"fifday"})])
}]

export { render, staticRenderFns }